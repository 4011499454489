import { z } from 'zod';
const bannerValidationSchema = z
    .object({
    variant: z.enum(['info', 'error', 'warn']),
    message: z.string(),
})
    .nullable();
const featurePoolListingSchema = z
    .object({ listing: z.array(z.string()) })
    .nullable();
const frequentlyAskedQuestionsValidationSchema = z
    .object({
    sections: z
        .array(z.object({
        question: z.string(),
        answers: z.array(z.string()).nullish(),
        answer: z.string().nullish(),
    }))
        .nullish(),
})
    .nullable();
const smsProviderValidationSchema = z.enum(['twilio', 'postman']);
const FreePassEntryDatesSchema = z.object({
    dates: z.array(z
        .string()
        .datetime({ offset: true })
        .transform((v) => new Date(v))),
});
// Note(important!): When parsing date strings, use z.string().datetime({ offset: true })
export const APP_FEATURES = {
    'popularity-threshold': z.number(),
    'popularity-indicator': z.boolean(),
    'cloudflare-turnstile': z.boolean(),
    'whitelist-members': z.boolean(),
    'whitelist-principals': z.boolean(),
    'admin-application-url': z.string(),
    'member-initiated-refund': z.boolean(),
    'imitate-member': z.boolean(),
    'sms-provider': smsProviderValidationSchema,
    'frequently-asked-questions': frequentlyAskedQuestionsValidationSchema,
    'quota-limit-include-children': z.boolean(),
    banner: bannerValidationSchema,
    'pass-single-entry-purchase-limit': z.number(),
    'admin-ability-to-link-accounts': z.boolean(),
    'admin-pass-consume-v2': z.boolean(),
    'admin-pass-qr-trial': z.boolean(),
    'feature-pool-listing': featurePoolListingSchema,
    'senior-citizen-passes': z.boolean(),
    'checkout-sessions': z.boolean(),
    'pass-qr-trial-infobox': z.string(),
    'free-pass-entry-dates': FreePassEntryDatesSchema,
    'credit-adjustment': z.boolean(),
    'programme-full-text-search-rank': z.boolean(),
};
