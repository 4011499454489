import { AGE_COMPARATOR_MAP, isPassAgeGroup, } from '@activesg/db';
import { assertUnreachable } from './asserts.js';
import { calculateYearAge, ld } from './dates.js';
import { dayjs } from './dayjs/index.js';
import { toTitleCase } from './string.js';
export function isEligibleForSeniorCitizenPass({ dob, residentialStatus, }) {
    return calculateYearAge(dob) >= 65 && residentialStatus === 'CITIZEN';
}
export function passAgeSortComparator(lhs, rhs) {
    let result = 0;
    const maxAGE_COMPARATOR_MAP = Math.max(...Object.values(AGE_COMPARATOR_MAP)) + 1;
    result =
        (isPassAgeGroup(lhs.ageGroup)
            ? AGE_COMPARATOR_MAP[lhs.ageGroup]
            : maxAGE_COMPARATOR_MAP) -
            (isPassAgeGroup(rhs.ageGroup)
                ? AGE_COMPARATOR_MAP[rhs.ageGroup]
                : maxAGE_COMPARATOR_MAP);
    // sort on id if the above is equal so the order is deterministic
    if (result === 0 && lhs.id && rhs.id) {
        result = lhs.id.localeCompare(rhs.id);
    }
    return result;
}
export function renderPassValidity(days) {
    if (new Date() < new Date('2024-08-28T00:00:00.000+08:00')) {
        return dayjs.duration(days, 'days').humanize().replace('a', '1');
    }
    return `${days}-day`;
}
function renderTimeRestriction(timeRestriction) {
    switch (timeRestriction) {
        case 'ALL':
            return 'Any Time';
        case 'PEAK_ONLY':
            return 'Peak';
        case 'OFF_PEAK_ONLY':
            return 'Off-Peak';
        default:
            return assertUnreachable(timeRestriction);
    }
}
export function renderTimeRestrictionLabel({ timeRestriction, timeRestrictionLabel, }) {
    if (timeRestrictionLabel) {
        return timeRestrictionLabel;
    }
    return renderTimeRestriction(timeRestriction);
}
export function constructPassTimeRestrictionLabel(title, description) {
    if (description) {
        return `${title}, ${description}`;
    }
    return title;
}
export function renderPassAgeCondition({ minAge, maxAge, }) {
    if (minAge !== null && maxAge !== null) {
        return `${minAge} to ${maxAge} years old`;
    }
    if (minAge !== null) {
        return `${minAge} years old and above`;
    }
    if (maxAge !== null) {
        return `${maxAge} years old and below`;
    }
    return null;
}
export function calculatePassExpiry({ validityInDays, }) {
    return dayjs()
        .tz()
        .add(validityInDays - 1, 'days')
        .endOf('day')
        .toDate();
}
export const PASS_STATE = {
    PENDING_PAYMENT: 'Pending Payment',
    PAYMENT_PROCESSING: 'Payment Processing',
    EXPIRED: 'Expired',
    CANCELLED: 'Cancelled',
    VALID: 'Valid',
    USED: 'Used',
    FAILED: 'Failed',
    REFUND_PENDING: 'Refund Pending',
    REFUND_SUCCESS: 'Refund success',
    REFUND_FAILED: 'Refund failed',
};
export function getPassState({ cancelledAt, expireAt, paymentStatus, entries, isMultiUse, hasRefundOccurred, refundStatus, }) {
    if (hasRefundOccurred) {
        switch (refundStatus) {
            case 'SUCCEEDED':
                return PASS_STATE.REFUND_SUCCESS;
            case 'FAILED':
                return PASS_STATE.REFUND_FAILED;
        }
    }
    // TODO: Take into account consumption state.
    switch (paymentStatus) {
        case 'NONE':
            return PASS_STATE.PENDING_PAYMENT;
        case 'PENDING':
            return PASS_STATE.PAYMENT_PROCESSING;
        case 'CANCELLED':
            if (hasRefundOccurred)
                return PASS_STATE.REFUND_PENDING;
            return PASS_STATE.CANCELLED;
        case 'SUCCESS': {
            if (cancelledAt) {
                if (hasRefundOccurred)
                    return PASS_STATE.REFUND_PENDING;
                return PASS_STATE.CANCELLED;
            }
            if (ld.isAfter(new Date(), expireAt)) {
                return PASS_STATE.EXPIRED;
            }
            if (!isMultiUse && entries.length > 0) {
                return PASS_STATE.USED;
            }
            return PASS_STATE.VALID;
        }
        case 'FAILED':
            return PASS_STATE.FAILED;
        default:
            return assertUnreachable(paymentStatus);
    }
}
export const getAgeGroup = (age, ageGroups) => {
    for (const { group, minAge, maxAge } of ageGroups) {
        if ((!minAge || minAge <= age) && (!maxAge || maxAge >= age)) {
            return group;
        }
    }
    return null;
};
export function getRatesCancelled(data) {
    return (Object.entries(data.ageGroups)
        // Not using \u00D7 as it disappears in sms
        .map(([key, val]) => (val ? `${toTitleCase(key)} x ${val}` : ''))
        .filter((val) => !!val)
        .join(' '));
}
export const getCheckoutSessionLinkStatus = ({ status, expiresAt, }) => {
    switch (status) {
        // technically untrue, as NONE will always not be a state, putting so TS is happy
        case 'NONE':
        case 'PENDING': {
            if (expiresAt < new Date()) {
                return 'EXPIRED';
            }
            return 'PENDING';
        }
        case 'SUCCESS':
            return 'SUCCESS';
        default:
            status;
            throw new Error('Unreachable');
    }
};
export const HUMAN_READABLE_TIME_RESTRICTION_MAP = {
    ALL: 'All hours',
    OFF_PEAK_ONLY: 'Off-peak hours',
    PEAK_ONLY: 'Peak hours',
};
export const isPeak = () => {
    const dateTime = dayjs().tz('Asia/Singapore');
    const hour = dateTime.hour();
    const day = dateTime.day();
    const isPeakHour = hour < 7 || hour >= 16;
    const isSaturday = day === 6;
    const isSunday = day === 0;
    return isPeakHour || isSaturday || isSunday;
};
